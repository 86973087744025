<template>
  <div>
    <HorizontalChart v-if="languageStats" title="Languages" :data-set="languageStats" />

    <MapChart v-if="countryStats" :series="countryStats" class="mt-12" />
  </div>
</template>

<script>
import axios from "axios";
import { MapChart, HorizontalChart } from "cavea-design-system";

export default {
  name: "AdminDemographics",

  metaInfo: {
    title: "Demographics Statistics",
  },

  components: {
    MapChart,
    HorizontalChart,
  },

  data() {
    return {
      languageStats: null,
      countryStats: null,
    };
  },

  created() {
    this.fetchLanguageStats();
    this.fetchCountryMap();
  },

  methods: {
    async fetchLanguageStats() {
      this.languageStats = await axios
        .get(`${process.env.VUE_APP_API_URL}/admin/users/languages`)
        .then((res) => res?.data?.languageStats ?? [])
        .catch((error) => {
          console.error("Error fetching language stats", error);
          return [];
        });
    },

    async fetchCountryMap() {
      this.countryStats = await axios
        .get(`${process.env.VUE_APP_API_URL}/admin/users/countries`)
        .then((res) => res?.data?.countries ?? {})
        .catch((error) => {
          console.error("Error fetching country map", error);
          return [];
        });
    },
  },
};
</script>
